import React, { lazy } from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import Login from './containers/Login';

const Home = lazy(() => import('./containers/Home'));
const HomeSingle = lazy(() => import('./containers/HomeSingle'));
const PrintJobLabel = lazy(() => import('./containers/PrintJobLabel'));
const PrintFixtureAddresses = lazy(() => import('./containers/PrintFixtureAddresses'));
const Events = lazy(() => import('./containers/Events'));
const CloseOpps = lazy(() => import('./containers/CloseOpps'));
const Barcodes = lazy(() => import('./containers/Barcodes'));
const PAT = lazy(() => import('./containers/PAT'));
const PATLog = lazy(() => import('./containers/PATLog'));
const PATLogs = lazy(() => import('./containers/PATLogs'));
const PATUpload = lazy(() => import('./containers/PATUpload'));
const PATResult = lazy(() => import('./containers/PATResult'));
const AssetSearch = lazy(() => import('./containers/AssetSearch'));
const Designs = lazy(() => import('./containers/Designs'));
const PrintHistory = lazy(() => import('./containers/PrintHistory'));
const ManualPrint = lazy(() => import('./containers/ManualPrint'));
const Metrics = lazy(() => import('./containers/Metrics'));
const ListUsers = lazy(() => import('./containers/users/ListUsers'));
// const Logistics = lazy(() => import('./containers/logistics/Logistics'));
const Event = lazy(() => import('./containers/Event'));
const CDMSetup = lazy(() => import('./containers/hands/CDMSetup'));
const CPPSetup = lazy(() => import('./containers/hands/CPPSetup'));
const MSSetup = lazy(() => import('./containers/hands/MSSetup'));
const RASetup = lazy(() => import('./containers/hands/RASetup'));
const LinkShortener = lazy(() => import('./containers/tools/LinkShortener'));
const Hours = lazy(() => import('./containers/hours/Hours'));
const Holiday = lazy(() => import('./containers/hours/Holiday'));
const SubmitHours = lazy(() => import('./containers/hours/SubmitHours'));
const Expenses = lazy(() => import('./containers/hours/Expenses'));
const InitialiseUser = lazy(() => import('./containers/users/InitialiseUser'));
const HolidayRequest = lazy(() => import('./containers/hours/HolidayRequest'));
const HolidayAdmin = lazy(() => import('./containers/hours/HolidayAdmin'));
const AdminHoursOverview = lazy(() => import('./containers/hours/AdminHoursOverview'));
const TOILAdmin = lazy(() => import('./containers/hours/TOILAdmin'));
const UserSettings = lazy(() => import('./containers/hours/UserSettings'));
const Chaps = lazy(() => import('./containers/chaps/Chaps'));
const ChapsProgress = lazy(() => import('./containers/chaps/ChapsProgress'));
const TwoFA = lazy(() => import('./containers/tools/TwoFA'));

export const routeList = [
  {
    path: ['/'],
    name: 'Dashboard',
    icon: 'fas fa-tachometer-alt text-primary',
    component: Home,
    routeKey: 'dash-home',
    scope: ['lightwave/generic', 'lightwave/admin', 'lightwave/user'],
    subPages: [
      {
        path: ['/single-day'],
        component: HomeSingle,
        routeKey: 'dash-home-single',
      },
    ],
  },
  {
    path: ['/metrics'],
    name: 'Metrics',
    icon: 'fas fa-chart-line text-primary',
    routeKey: 'metrics-home',
    scope: ['lightwave/admin'],
    component: Metrics,
  },
  // {
  //   path: ['/logistics'],
  //   name: 'Logistics',
  //   icon: 'fas fa-truck-fast text-primary',
  //   routeKey: 'logistics-home',
  //   requiresAdmin: true,
  //   component: Logistics,
  // },
  {
    path: ['/health-and-safety'],
    name: 'Health & Safety',
    icon: 'fas fa-helmet-safety text-primary',
    routeKey: 'hands-home',
    scope: ['lightwave/admin'],
    subNav: [
      {
        path: ['/health-and-safety/events'],
        name: 'Events',
        icon: 'fas fa-calendar-alt text-muted',
        component: Events,
        routeKey: 'events-list',
        subPages: [
          {
            path: ['/health-and-safety/events/:eventId'],
            component: Event,
            routeKey: 'hands-event-individual',
          },
        ],
      },
      {
        path: ['/health-and-safety/cdm'],
        name: 'CDM Setup',
        icon: 'fas fa-screwdriver-wrench text-muted',
        component: CDMSetup,
        routeKey: 'cdm-setup',
        subPages: [
          {
            path: ['/health-and-safety/cdm/cpp'],
            component: CPPSetup,
            routeKey: 'hands-cpp-setup',
          },
          {
            path: ['/health-and-safety/cdm/ms'],
            component: MSSetup,
            routeKey: 'hands-ms-setup',
          },
          {
            path: ['/health-and-safety/cdm/ra'],
            component: RASetup,
            routeKey: 'hands-ra-setup',
          },
        ],
      },
    ],
  },
  {
    path: ['/jobs'],
    name: 'Jobs',
    icon: 'fas fa-calendar text-primary',
    routeKey: 'jobs-home',
    scope: ['lightwave/generic', 'lightwave/admin', 'lightwave/user'],
    subNav: [
      {
        path: ['/jobs/label'],
        name: 'Print Flightcase Labels',
        icon: 'fas fa-tag text-muted',
        component: PrintJobLabel,
        routeKey: 'jobs-labels',
        scope: ['lightwave/generic', 'lightwave/admin', 'lightwave/user'],
      },
      {
        path: ['/jobs/addresses'],
        name: 'Print Fixture Addresses',
        icon: 'fas fa-lightbulb text-muted',
        component: PrintFixtureAddresses,
        routeKey: 'jobs-addresses',
        scope: ['lightwave/generic', 'lightwave/admin', 'lightwave/user'],
      },
      {
        path: ['/jobs/close'],
        name: 'Close Opportunities',
        icon: 'fas fa-xmark text-muted',
        component: CloseOpps,
        routeKey: 'jobs-close',
        scope: ['lightwave/admin'],
      },
    ],
  },
  {
    path: ['/barcodes'],
    name: 'Labels',
    icon: 'fas fa-barcode text-primary',
    routeKey: 'barcodes-home',
    scope: ['lightwave/generic', 'lightwave/admin', 'lightwave/user'],
    subNav: [
      {
        path: ['/barcodes/items', '/barcodes/items/:itemId', '/barcodes/items/:itemId/:pageNumber'],
        name: 'View & Print for Assets',
        icon: 'fas fa-print text-muted',
        component: Barcodes,
        routeKey: 'barcodes-list',
        scope: ['lightwave/generic', 'lightwave/admin', 'lightwave/user'],
      },
      {
        path: ['/barcodes/history'],
        name: 'Print History',
        icon: 'fas fa-history text-muted',
        component: PrintHistory,
        routeKey: 'barcodes-print-history',
        scope: ['lightwave/generic', 'lightwave/admin', 'lightwave/user'],
      },
      {
        path: ['/barcodes/assets', '/barcodes/assets/:assetId'],
        name: 'Search by Barcode',
        icon: 'fas fa-search text-muted',
        component: AssetSearch,
        routeKey: 'barcodes-serach',
        scope: ['lightwave/generic', 'lightwave/admin', 'lightwave/user'],
      },
      {
        path: ['/barcodes/formats'],
        name: 'Barcode Formats',
        icon: 'far fa-images text-muted',
        component: Designs,
        routeKey: 'barcodes-formats',
        scope: ['lightwave/generic', 'lightwave/admin', 'lightwave/user'],
        subPages: [
          {
            path: ['/barcodes/formats/:format'],
            component: ManualPrint,
            routeKey: 'barcodes-format-individual',
          },
        ],
      },
    ],
  },
  {
    path: ['/pat'],
    name: 'PAT Testing',
    icon: 'fas fa-plug text-primary',
    routeKey: 'pat-home',
    scope: ['lightwave/admin', 'lightwave/user'],
    subNav: [
      {
        path: ['/pat/results'],
        name: 'Test Results',
        icon: 'fas fa-square-poll-vertical text-muted',
        component: PAT,
        routeKey: 'pat-results-list',
        scope: ['lightwave/admin', 'lightwave/user'],
        subPages: [
          {
            path: ['/pat/results/:itemBarcode'],
            component: PATResult,
            routeKey: 'pat-result-entry',
            scope: ['lightwave/admin', 'lightwave/user'],
          },
        ],
      },
      {
        path: ['/pat/upload'],
        name: 'Upload Current Database',
        icon: 'fas fa-cloud-arrow-up text-muted',
        component: PATUpload,
        routeKey: 'pat-upload-database',
        scope: ['lightwave/admin', 'lightwave/user'],
      },
      {
        path: ['/pat/logs'],
        name: 'Logs',
        icon: 'fas fa-message text-muted',
        component: PATLogs,
        routeKey: 'pat-upload-logs',
        scope: ['lightwave/admin', 'lightwave/user'],
        subPages: [
          {
            path: ['/pat/logs/:runId'],
            component: PATLog,
            routeKey: 'pat-upload-log-entry',
            scope: ['lightwave/admin', 'lightwave/user'],
          },
        ],
      },
    ],
  },
  {
    path: ['/tools'],
    name: 'Tools',
    icon: 'fas fa-toolbox text-primary',
    routeKey: 'toolbox-home',
    scope: ['lightwave/admin', 'lightwave/user', 'lightwave/generic'],
    subNav: [
      {
        path: ['/tools/link-shortener'],
        name: 'Link Shortener',
        icon: 'fas fa-link text-muted',
        component: LinkShortener,
        routeKey: 'link-shortener',
        scope: ['lightwave/admin'],
      },
      {
        path: ['/tools/chaps'],
        name: 'Chaps Converter',
        icon: 'fas fa-shuffle text-muted',
        component: Chaps,
        routeKey: 'chaps',
        scope: ['lightwave/admin', 'lightwave/user', 'lightwave/generic'],
      },
      {
        path: ['/tools/chaps-progress'],
        name: 'Chaps Progress',
        icon: 'fas fa-list-check text-muted',
        component: ChapsProgress,
        routeKey: 'chaps-progress',
        scope: ['lightwave/admin', 'lightwave/user', 'lightwave/generic'],
      },
      {
        path: ['/tools/2fa'],
        name: 'iPad/Mac 2FA Codes',
        icon: 'fas fa-key text-muted',
        component: TwoFA,
        routeKey: '2-fa',
        scope: ['lightwave/admin', 'lightwave/user', 'lightwave/generic'],
      },
    ],
  },
  {
    path: ['/users'],
    name: 'User Admin',
    icon: 'fas fa-users text-primary',
    routeKey: 'users-home',
    scope: ['lightwave/admin'],
    subNav: [
      {
        path: ['/users/list'],
        name: 'User List',
        icon: 'fas fa-address-book text-muted',
        component: ListUsers,
        routeKey: 'users-list',
        scope: ['lightwave/admin'],
        subPages: [
          {
            path: ['/users/:userUID'],
            component: ListUsers,
            routeKey: 'users-list-user',
            scope: ['lightwave/admin'],
          },
          {
            path: ['/users/:userUID/initialise'],
            component: InitialiseUser,
            routeKey: 'users-initialise-user',
            scope: ['lightwave/admin'],
          },
        ],
      },
      {
        path: ['/hours/admin/overview'],
        name: 'User Hours',
        icon: 'fas fa-calendar-days text-muted',
        component: AdminHoursOverview,
        routeKey: 'hours-overview-admin',
        scope: ['lightwave/admin'],
      },
      {
        path: ['/hours/holiday-admin'],
        name: 'Holiday',
        icon: 'fas fa-door-open text-muted',
        component: HolidayAdmin,
        routeKey: 'holiday-admin',
        scope: ['lightwave/admin'],
      },
      {
        path: ['/hours/toil-admin'],
        name: 'TOIL',
        icon: 'fas fa-clock-rotate-left text-muted',
        component: TOILAdmin,
        routeKey: 'toil-admin',
        scope: ['lightwave/admin'],
      },
      {
        path: ['/expenses/expenses-admin'],
        name: 'Expenses',
        icon: 'fas fa-piggy-bank text-muted',
        component: Expenses,
        routeKey: 'expenses-admin',
        scope: ['lightwave/admin'],
      },
      {
        path: ['/hours/settings'],
        name: 'Settings',
        icon: 'fas fa-cog text-muted',
        component: UserSettings,
        routeKey: 'user-settings-admin',
        scope: ['lightwave/admin'],
      },
    ],
  },
  {
    path: ['/hours'],
    name: 'Hours & Expenses',
    icon: 'fas fa-business-time text-primary',
    routeKey: 'hours-home',
    scope: ['lightwave/admin', 'lightwave/user'],
    subNav: [
      {
        path: ['/hours/overview'],
        name: 'Overview',
        icon: 'fas fa-calendar-days text-muted',
        component: Hours,
        routeKey: 'hours-overview',
        scope: ['lightwave/admin', 'lightwave/user'],
      },
      {
        path: ['/hours/submit'],
        name: 'Submit Hours',
        icon: 'fas fa-user-clock text-muted',
        component: SubmitHours,
        routeKey: 'hours-submit',
        scope: ['lightwave/admin', 'lightwave/user'],
      },
      {
        path: ['/hours/timeoff'],
        name: 'Time Off',
        icon: 'fas fa-plane-departure text-muted',
        component: Holiday,
        routeKey: 'hours-holiday',
        scope: ['lightwave/admin', 'lightwave/user'],
        subPages: [
          {
            path: ['/hours/timeoff/request'],
            component: HolidayRequest,
            routeKey: 'hours-holiday-request',
            scope: ['lightwave/admin', 'lightwave/user'],
          },
        ],
      },
      {
        path: ['/expenses'],
        name: 'Expenses',
        icon: 'fas fa-file-invoice-dollar text-muted',
        component: Expenses,
        routeKey: 'expenses-overview',
        scope: ['lightwave/admin', 'lightwave/user'],
      },
    ],
  },
];

const getRoutes = (routes) => routes.map((route) => {
  if (route.subNav && route.subNav.length > 0) {
    return (
      getRoutes(route.subNav)
    );
  }
  if (route.subPages && route.subPages.length > 0) {
    const subPages = getRoutes(route.subPages);
    const page = (
      <AuthenticatedRoute key={route.path[0]} exact path={route.path} component={route.component} />
    );
    return [page, subPages];
  }
  return (
    <AuthenticatedRoute key={route.path[0]} exact path={route.path} component={route.component} />
  );
});

export default function Routes() {
  const { pathname } = useLocation();

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      {getRoutes(routeList)}
      <AuthenticatedRoute key="dash-full-screen" exact path="/full-screen/dashboard" component={Home} />
      <AuthenticatedRoute key="dash-single-full-screen" exact path="/full-screen/single-day" component={HomeSingle} />
    </Switch>
  );
}
